
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































































.additional-items {
  margin-top: 2rem;

  @include mq($until: m) {
    padding-top: 2.4rem;
    padding-bottom: 0;
    border-top: 1px solid $c-black-40;
  }
}

.additional-item {
  @extend %shadow;

  position: relative;
  display: flex;
  align-items: center;

  @include mq($until: m) {
    align-items: flex-start;
    box-shadow: none;

    & + & {
      padding-top: 2.4rem;
      padding-bottom: 0;
      border-top: 1px solid $c-black-40;
    }
  }

  @include mq(m) {
    padding: 3rem col(1, 24);
    border-radius: 16px;
  }
}

.additional-item + .additional-item {
  margin-top: 2.4rem;

  @include mq(m) {
    margin-top: 2rem;
  }
}

.additional-item-container {
  display: flex;

  @include mq($until: m) {
    flex-direction: column;
    padding-top: 0.4rem;
  }

  @include mq(m) {
    align-items: center;
    flex: 1;
  }
}

.additional-item__category {
  margin-right: col(1, 22);
  flex: 0;

  &__link {
    @extend %tag;
    @extend %text-nodecoration;

    display: inline-flex;
    margin-right: 2rem;
    color: $c-white;
    background-color: $c-blue;
    border-color: $c-blue;

    @include mq(m) {
      margin-right: 0;
    }
  }
}

.additional-item__title {
  @extend %text-nodecoration;
  @include fluid(
    font-size,
    (
      s: 14px,
      xl: 16px,
    )
  );

  color: $c-dark-blue;

  @include mq($until: m) {
    margin-bottom: 1rem;
  }

  @include mq(m) {
    margin-right: col(1, 22);
    width: col(18, 22);
  }
}

.additional-item__date,
.additional-item__location {
  color: $c-black-60;
  font-size: 1.4rem;
}

.additional-item__link {
  @include get-all-space;
}

.load-more {
  @include fluid(
    margin-top,
    (
      s: 20px,
      xl: 40px,
    )
  );
  @include fluid(
    margin-bottom,
    (
      s: 40px,
      xl: 80px,
    )
  );
  display: flex;
  justify-content: center;
}

// Transition

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

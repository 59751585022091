
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































.cta {
  margin-bottom: 9rem;

  ::v-deep {
    [class*='action--'] {
      background-color: $c-yellow;

      .action__label {
        color: $c-white;
      }

      svg {
        stroke: $c-white;
      }
    }
  }
}

.cta-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cta__title {
  margin-bottom: 3.6rem;
  max-width: 40rem;
  color: $c-dark-blue;

  ::v-deep {
    b,
    strong {
      @extend %fw-light;

      // color: $c-yellow;
    }
  }
}

.cta__headline {
  margin: auto;
  margin-bottom: 1.5rem;
  max-width: 52rem;
  color: $c-blue;
  letter-spacing: 0.5px;

  ::v-deep {
    p {
      @include fluid(
        margin-bottom,
        (
          s: 20px,
          xl: 30px,
        )
      );
    }
  }
}

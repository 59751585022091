
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































.news-single__content {
  @include fluid(
    margin-bottom,
    (
      s: 100px,
      xl: 200px,
    )
  );

  max-width: 72rem;
  margin-inline: auto;

  @include mq($until: m) {
    padding-right: col(1, 24);
    padding-left: col(1, 24);
  }
}


          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































































































































































.list {
  @include fluid(
    margin-bottom,
    (
      s: 80px,
      xl: 130px,
    )
  );

  position: relative;
  margin-top: 2rem;

  ::v-deep {
    [class*='action--'] {
      @include mq(m) {
        background-color: $c-white;
      }
    }

    .load-more [class*='action--'] {
      background-color: $c-dark-blue;

      .action__label {
        color: $c-white;
      }

      svg {
        path {
          fill: $c-white;
        }
      }
    }
  }
}

.list__item {
  @extend %shadow;

  position: relative;
  display: flex;
  align-items: center;

  @include mq($until: m) {
    align-items: flex-start;
    box-shadow: none;

    & + & {
      padding-top: 2.4rem;
      padding-bottom: 0;
      border-top: 1px solid $c-black-40;
    }
  }

  @include mq(m) {
    padding: 3rem col(1, 24);
    border-radius: 16px;
  }
}

.list__item + .list__item {
  margin-top: 2.4rem;

  @include mq(m) {
    margin-top: 2rem;
  }
}

.list__item-container {
  display: flex;

  @include mq($until: m) {
    flex-direction: column;
    padding-top: 0.4rem;
  }

  @include mq(m) {
    align-items: center;
    flex: 1;
  }
}

.list__item__category {
  margin-right: col(1, 22);
  flex: 0;

  &__link {
    @extend %tag;
    @extend %text-nodecoration;

    display: inline-flex;
    margin-right: 2rem;
    color: $c-white;
    background-color: $c-blue;
    border-color: $c-blue;

    @include mq(m) {
      margin-right: 0;
    }
  }
}

.list__item__title {
  @extend %text-nodecoration;
  @include fluid(
    font-size,
    (
      s: 14px,
      xl: 16px,
    )
  );

  color: $c-dark-blue;

  @include mq($until: m) {
    margin-bottom: 1rem;
  }

  @include mq(m) {
    margin-right: col(1, 22);
    width: col(18, 22);
  }
}

.list__item__date,
.list__item__location {
  color: $c-black-60;
  font-size: 1.4rem;
}

.list__filters {
  margin-bottom: 6rem;
  border-bottom: 1px solid $c-black-40;

  @include mq(m) {
    padding: 0 col(1, 24);
  }
}

.list__filters__items {
  @extend %list-nostyle;

  display: flex;
}

.list__filters__item {
  @extend %fw-semi-bold;

  padding: 0 2rem 3rem;
  font-size: 1.3rem;
  color: $c-black;
  opacity: 0.5;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  transition: all 0.3s ease-in-out;

  &.is-active,
  &:hover {
    color: $c-dark-blue;
    opacity: 1;
    border-bottom: 3px solid $c-yellow;
  }
}

.list-error {
  padding: 0 col(1, 24);
}

.list__item__link {
  @include get-all-space;
}

.list__item__download {
  ::v-deep {
    .action__label {
      font-size: 1.4rem;
    }

    .action__arrow {
      transform: rotate(90deg);

      .active & {
        svg {
          &:first-child {
            animation: slideBar 0.4s linear;
          }

          &:last-child {
            animation: slideArrow 0.4s linear;
          }
        }
      }
    }
  }
}

.additional-items {
  margin-top: 2rem;

  @include mq($until: m) {
    padding-top: 2.4rem;
    padding-bottom: 0;
    border-top: 1px solid $c-black-40;
  }
}

.load-more {
  @include fluid(
    margin-top,
    (
      s: 20px,
      xl: 40px,
    )
  );
  @include fluid(
    margin-bottom,
    (
      s: 40px,
      xl: 80px,
    )
  );
  display: flex;
  justify-content: center;
}

// Transition

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
  transition-delay: 500ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@keyframes slideBar {
  0% {
    transform: translateX(0);
  }

  45% {
    transform: translateX(2.8rem);
    opacity: 0;
  }

  55% {
    transform: translateX(-2.8rem);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideArrow {
  0% {
    transform: translateX(-1.4rem);
  }

  45% {
    transform: translateX(3.2rem);
    opacity: 0;
  }

  55% {
    transform: translateX(-3.2rem);
    opacity: 1;
  }

  100% {
    transform: translateX(-1.4rem);
  }
}
